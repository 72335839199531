import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';

import styles from './TransportOrderDetailsPageLayout.scss';

import { useParams } from 'react-router-dom';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { goBackIfHasRePath } from 'common/utils/history';
import GoogleMapContext from 'common/contexts/google-map-context';
import HorizontalColumnsLayout from 'common/layouts/HorizontalColumnsLayout/HorizontalColumnsLayout';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import {
    selectTransportOrderDetails,
    selectTransportOrderDetailsRequestStatus,
} from 'common/store/transport-order-details/selectors';
import { fetchTransportOrderDetails } from 'common/store/transport-order-details/actions';
import TransportOrderDetails from './TransportOrderDetails/TransportOrderDetails';
import TransportOrderStatusPill from 'common/components/status-pill/TransportOrderStatusPill/TransportOrderStatusPill';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import DriversAssignmentFooterContent from './footers/DriversAssignmentFooterContent/DriversAssignmentFooterContent';
import { StyleGuideColorsEnum } from 'common/constants';
import {
    selectAssignmentRequestStatus,
    selectReAssignmentRequestStatus,
} from 'common/store/driver-assigment/selectors';
import { selectAssignmentRequestStatus as selectSpotAssetsAssignmentRequestStatus } from 'common/store/spot-assets-assigment/selectors';
import StatusFooterContent from './footers/StatusFooterContent/StatusFooterContent';
import { ApiTransportOrderStatusT, TransportOrderStatusEnum, TransportOrderTypeEnum } from 'common/utils/api/models';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import MoreIcon from 'common/icons/MoreIcon';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import CloseIcon from 'common/icons/CloseIcon';
import DeclineOrderConfirmation, {
    DeclineOrderConfirmationDataT,
} from './dialogs/DeclineOrderConfirmation/DeclineOrderConfirmation';
import SideBarLayoutContextWrap from '../LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import StartDriversAssignmentFooterContent from 'common/layouts/TransportOrderDetailsPageLayout/footers/StartDriversAssignmentFooterContent/StartDriversAssignmentFooterContent';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import EntitySidebarSkeleton from 'common/components/order-details/EntitySidebarSkeleton/EntitySidebarSkeleton';
import HeaderSideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLoader/HeaderSideBarLoader';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import UnassignDriversConfirmation, {
    UnassignDriversConfirmationDataT,
} from 'common/layouts/TransportOrderDetailsPageLayout/dialogs/UnassignDriversConfirmation/UnassignDriversConfirmation';
import { reAssign } from 'common/store/driver-assigment/actions';
import { selectPermissions } from 'common/store/auth/selectors';
import AcceptDeclinedOrderConfirmation, {
    AcceptDeclinedOrderConfirmationDataT,
} from './dialogs/AcceptDeclinedOrderConfirmation/AcceptDeclinedOrderConfirmation';
import { BooleanParam, useQueryParams } from 'use-query-params';
import StartSpotAssetsAssignmentFooterContent from 'common/layouts/TransportOrderDetailsPageLayout/footers/StartSpotAssetsAssignmentFooterContent/StartSpotAssetsAssignmentFooterContent';
import SpotAssetsAssignmentFooterContent from 'common/layouts/TransportOrderDetailsPageLayout/footers/SpotAssetsAssignmentFooterContent/SpotAssetsAssignmentFooterContent';
import ParanjaSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/ParanjaSideBarLayout/ParanjaSideBarLayout';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { isNonNil } from 'common/utils';

import { StateMachineEntityDescriptorT } from 'common/store/state-machine/models';
import { updateEntityStatus } from 'common/store/state-machine/slice';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { stateMachineRefreshChannel } from 'common/store/state-machine/channels';
import { transportOrderRefreshChannel } from 'common/store/transport-order-details/channels';
import TransportOrderRouteMap from './TransportOrderRouteMap/TransportOrderRouteMap';
import {
    checkIsBrokerTransportOrderDetails,
    checkIsCarrierTransportOrderDetails,
} from 'common/store/transport-order-details/type-guards';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { useStateMachine } from 'common/utils/hooks/useStateMachine';

const cx = classNames.bind(styles);

type PropsT = {
    isBroker: boolean;
    isReadOnly?: boolean;
    goToBack: () => void;
    goToTrailerDetails: (trailerId: TrailerIdT | null) => void;
    goToTruckDetails: (truckId: TruckIdT | null) => void;
    goToDriverDetails?: (driverId: DriverIdT | null) => void;
    goToSpotRequestDetails: (spotRequestId: SpotRequestIdT | null) => void;
};

enum QueryKeysEnum {
    driversAssignment = 'drivers-assignment',
    spotAssetsAssignment = 'spot-assets-assignment',
}

const TransportOrderDetailsPageLayout: React.FC<PropsT> = React.memo((props) => {
    const { isBroker, goToBack, goToTrailerDetails, goToTruckDetails, goToDriverDetails, goToSpotRequestDetails } =
        props;

    const { partnerId } = usePartnerContext();

    const params = useParams<{ transportOrderId: string }>();
    const { transportOrderId } = params;

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    const { t } = useTranslation();

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.driversAssignment]: BooleanParam,
        [QueryKeysEnum.spotAssetsAssignment]: BooleanParam,
    });

    const acceptDeclinedOrderConfirmation = useModalDialog<AcceptDeclinedOrderConfirmationDataT>();
    const declineOrderConfirmation = useModalDialog<DeclineOrderConfirmationDataT>();
    const unassignDriversConfirmation = useModalDialog<UnassignDriversConfirmationDataT>();

    const permissions = useSelector(selectPermissions);

    const transportOrderDetails = useSelector(selectTransportOrderDetails(transportOrderId));
    const transportOrderDetailsRequestStatus = useSelector(selectTransportOrderDetailsRequestStatus(transportOrderId));

    const isReadOnly = props.isReadOnly || transportOrderDetails?.type === TransportOrderTypeEnum.trailer;

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchTransportOrderDetails(partnerId, transportOrderId, { isForceUpdate: false }));
    }, [partnerId, transportOrderId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchTransportOrderDetails(partnerId, transportOrderId, { isForceUpdate: false }));
    }, [partnerId, transportOrderId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshTransportPageHandler = React.useCallback(() => {
        dispatch(fetchTransportOrderDetails(partnerId, transportOrderId, { isForceUpdate: true }));
    }, [partnerId, transportOrderId]);
    useChannelSubscribe(stateMachineRefreshChannel, refreshTransportPageHandler);
    useChannelSubscribe(transportOrderRefreshChannel, refreshTransportPageHandler);

    const closeOrderDetails = () => {
        goBackIfHasRePath(() => {
            goToBack();
        });
    };

    const isActiveDriversAssignment = !!query[QueryKeysEnum.driversAssignment];
    const closeDriversAssignments = () => {
        changeQuery({
            [QueryKeysEnum.driversAssignment]: null,
        });
    };

    const openDriversAssignments = () => {
        changeQuery({
            [QueryKeysEnum.driversAssignment]: true,
        });
    };

    const assignmentDriverRequestStatus = useSelector(selectAssignmentRequestStatus);
    React.useEffect(() => {
        if (assignmentDriverRequestStatus.ok) {
            closeDriversAssignments();
        }
    }, [assignmentDriverRequestStatus.ok]);

    const isActiveSpotAssetsAssignment = !!query[QueryKeysEnum.spotAssetsAssignment];
    const closeSpotAssetsAssignments = () => {
        changeQuery({
            [QueryKeysEnum.spotAssetsAssignment]: null,
        });
    };

    const openSpotAssetsAssignments = () => {
        changeQuery({
            [QueryKeysEnum.spotAssetsAssignment]: true,
        });
    };

    const spotAssetsAssignmentRequestStatus = useSelector(selectSpotAssetsAssignmentRequestStatus);
    React.useEffect(() => {
        if (spotAssetsAssignmentRequestStatus.ok) {
            closeSpotAssetsAssignments();
        }
    }, [spotAssetsAssignmentRequestStatus.ok]);

    const isNeedDriversAssignment =
        checkIsCarrierTransportOrderDetails(transportOrderDetails) && transportOrderDetails?.availableToAssignDriver;

    const isNeedSpotAssetsAssignment =
        checkIsCarrierTransportOrderDetails(transportOrderDetails) &&
        !transportOrderDetails?.truck &&
        !transportOrderDetails?.trailer &&
        transportOrderDetails?.availableToAssignAssets;

    const descriptor = useMemo((): StateMachineEntityDescriptorT => {
        return {
            entityType: 'TRANSPORTATION_ORDER',
            entityId: transportOrderId || '-',
        };
    }, [transportOrderId]);

    const { availableSubjectStatuses, updateRequest } = useStateMachine<ApiTransportOrderStatusT>(descriptor);

    useCloseModalDialogAfterRequest(updateRequest, [declineOrderConfirmation, acceptDeclinedOrderConfirmation]);

    const isAvailableDecline =
        permissions.canDeclineTransportOrders &&
        ((checkIsCarrierTransportOrderDetails(transportOrderDetails) && transportOrderDetails?.availableToDecline) ||
            (checkIsBrokerTransportOrderDetails(transportOrderDetails) && transportOrderDetails?.availableToCancel));

    const options: Array<DropdownControlOptionT | null> = [
        isAvailableDecline
            ? {
                  label: (
                      <DropdownControlOptionLabel
                          icon={<CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                          label={t('common:transport-order-details.actions.decline')}
                      />
                  ),
                  onSelect: () => {
                      declineOrderConfirmation.setData({});
                  },
              }
            : null,
    ];

    const handleConfirmDecline = () => {
        dispatch(
            updateEntityStatus({
                update: {
                    entityType: 'TRANSPORTATION_ORDER',
                    entityId: transportOrderId || '-',
                    status: TransportOrderStatusEnum.canceled,
                },
                descriptor,
            }),
        );
    };

    const handleConfirmAcceptDeclined = () => {
        dispatch(
            updateEntityStatus({
                update: {
                    entityType: 'TRANSPORTATION_ORDER',
                    entityId: transportOrderId || '-',
                    status: TransportOrderStatusEnum.assigned,
                },
                descriptor,
            }),
        );
    };

    const reAssignRequest = useSelector(selectReAssignmentRequestStatus);
    useCloseModalDialogAfterRequest(reAssignRequest, [unassignDriversConfirmation]);

    const handleReAssignment = (): void => {
        const driverIds = (transportOrderDetails?.drivers || []).map((driver) => driver.id).filter(isNonNil);

        dispatch(reAssign(transportOrderId, driverIds));
    };

    const isShowLineLoader = transportOrderDetailsRequestStatus.loading && !!transportOrderDetails;
    const isShowOverlayLoader = !transportOrderDetailsRequestStatus.error && !transportOrderDetails;

    const renderFooter = () => {
        if (isReadOnly) {
            return null;
        }

        if (isActiveDriversAssignment) {
            return (
                <FooterSideBarLayout isTransparent hasShadow>
                    <DriversAssignmentFooterContent
                        transportOrderId={transportOrderId}
                        transportOrderDetails={transportOrderDetails}
                        onCancel={closeDriversAssignments}
                    />
                </FooterSideBarLayout>
            );
        }

        if (isActiveSpotAssetsAssignment) {
            return (
                <>
                    <ParanjaSideBarLayout />
                    <FooterSideBarLayout isTransparent hasShadow>
                        <SpotAssetsAssignmentFooterContent
                            transportOrderId={transportOrderId}
                            transportOrderDetails={transportOrderDetails}
                            onCancel={closeSpotAssetsAssignments}
                        />
                    </FooterSideBarLayout>
                </>
            );
        }

        if (isNeedSpotAssetsAssignment) {
            return (
                <FooterSideBarLayout hasPaddings>
                    <StartSpotAssetsAssignmentFooterContent
                        transportOrderDetails={transportOrderDetails}
                        onStartAssignment={openSpotAssetsAssignments}
                    />
                </FooterSideBarLayout>
            );
        }

        if (isNeedDriversAssignment) {
            return (
                <FooterSideBarLayout hasPaddings>
                    <StartDriversAssignmentFooterContent
                        transportOrderId={transportOrderId}
                        transportOrderDetails={transportOrderDetails}
                        onStartAssignment={openDriversAssignments}
                    />
                </FooterSideBarLayout>
            );
        }

        const hasAvailableStatus = !!availableSubjectStatuses;
        if (hasAvailableStatus) {
            return (
                <FooterSideBarLayout hasPaddings>
                    <StatusFooterContent
                        descriptor={descriptor}
                        transportOrderId={transportOrderId}
                        transportOrderDetails={transportOrderDetails}
                        onReAssignment={() => {
                            unassignDriversConfirmation.setData({});
                        }}
                    />
                </FooterSideBarLayout>
            );
        }

        return null;
    };

    const isSetStateLoading = updateRequest.loading;

    return (
        <GoogleMapContext.Provider value={googleMapsContextValue}>
            <HorizontalColumnsLayout
                testSelector="transport-order-details"
                leftColumnTitle={t('common:transport-order-details.main-tabs.details')}
                leftColumnNode={
                    <SideBarLayoutContextWrap>
                        {isSetStateLoading && <LoaderOverlay />}
                        <HeaderSideBarLayout>
                            <HeaderSideBarContent
                                title={
                                    transportOrderDetails
                                        ? t('common:transport-order-details.title', {
                                              number: transportOrderDetails.number || '',
                                          })
                                        : null
                                }
                                onClose={closeOrderDetails}
                                afterTitleNode={
                                    <TransportOrderStatusPill
                                        status={transportOrderDetails?.status}
                                        isArchived={transportOrderDetails?.archivedByCarrier}
                                        isSpot={!!transportOrderDetails?.spotInfo?.id}
                                    />
                                }
                                rightNode={
                                    !isReadOnly ? (
                                        <DropdownControl
                                            options={options}
                                            renderTrigger={(isActive, onClick) => (
                                                <TransparentTrigger
                                                    isPressed={isActive}
                                                    onClick={onClick}
                                                    leftIcon={<MoreIcon />}
                                                    reflectionTheme={ReflectionThemeEnum.light}
                                                />
                                            )}
                                            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                        />
                                    ) : null
                                }
                            />
                            <HeaderSideBarLoader isShow={isShowLineLoader} />
                        </HeaderSideBarLayout>
                        <SideBarLayout>
                            {transportOrderDetails ? (
                                <TransportOrderDetails
                                    isBroker={isBroker}
                                    isReadOnly={isReadOnly}
                                    transportOrderDetails={transportOrderDetails}
                                    goToTrailerDetails={goToTrailerDetails}
                                    goToTruckDetails={goToTruckDetails}
                                    goToDriverDetails={goToDriverDetails}
                                    goToSpotRequestDetails={goToSpotRequestDetails}
                                />
                            ) : (
                                <EntitySidebarSkeleton />
                            )}
                        </SideBarLayout>
                        {renderFooter()}
                        {isShowOverlayLoader && <SideBarLoader />}
                    </SideBarLayoutContextWrap>
                }
                rightColumnTitle={t('common:transport-order-details.main-tabs.info')}
                rightColumnNode={
                    <TransportOrderRouteMap
                        key={transportOrderId}
                        transportOrderId={transportOrderId}
                        transportOrderDetails={transportOrderDetails}
                    />
                }
            />
            <AcceptDeclinedOrderConfirmation
                requestStatus={updateRequest}
                data={acceptDeclinedOrderConfirmation.data}
                onCancel={acceptDeclinedOrderConfirmation.onCancel}
                onClose={acceptDeclinedOrderConfirmation.onClose}
                onConfirm={handleConfirmAcceptDeclined}
            />
            <DeclineOrderConfirmation
                requestStatus={updateRequest}
                data={declineOrderConfirmation.data}
                onCancel={declineOrderConfirmation.onCancel}
                onClose={declineOrderConfirmation.onClose}
                onConfirm={handleConfirmDecline}
            />
            <UnassignDriversConfirmation
                data={unassignDriversConfirmation.data}
                onClose={unassignDriversConfirmation.onClose}
                onCancel={unassignDriversConfirmation.onCancel}
                onConfirm={handleReAssignment}
            />
        </GoogleMapContext.Provider>
    );
});

export default TransportOrderDetailsPageLayout;
