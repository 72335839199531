import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './StatusFooterContent.scss';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { useTranslation } from 'react-i18next';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { ApiTransportOrderStatusT, TransportOrderStatusEnum } from 'common/utils/api/models';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import { useDispatch, useSelector } from 'react-redux';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import { AvailableSubjectStatusesResponseT, StateMachineEntityDescriptorT } from 'common/store/state-machine/models';
import { updateEntityStatus } from 'common/store/state-machine/slice';
import { selectStateMachineItemState } from 'common/store/state-machine/selectors';
import ChangeBlockedStatusControlOptionLabel from 'common/components/dropdowns/options/ChangeBlockedStatusControlOptionLabel/ChangeBlockedStatusControlOptionLabel';
import ChangeBackwardStatusControlOptionLabel from 'common/components/dropdowns/options/ChangeBackwardStatusControlOptionLabel/ChangeBackwardStatusControlOptionLabel';
import ChangeForwardStatusControlOptionLabel from 'common/components/dropdowns/options/ChangeForwardStatusControlOptionLabel/ChangeForwardStatusControlOptionLabel';
import { addSeparatorAfterEachOption } from 'common/components/dropdowns/utils/add-separators';
import TransportOrderStatus from 'common/components/status/TransportOrderStatus/TransportOrderStatus';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    descriptor: StateMachineEntityDescriptorT;
    transportOrderId: TransportOrderIdT;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    onReAssignment: () => void;
};

const blockedI18nMap: Record<NonNullable<AvailableSubjectStatusesResponseT['description']>, string> = {
    HAS_LOADING_UNLOADING_SHIPMENT: 'change-shipment-status.blocked.HAS_LOADING_UNLOADING_SHIPMENT',
    TOUR_NOT_STARTED: 'change-shipment-status.blocked.TOUR_NOT_STARTED',
    NOT_FULLY_ASSIGN: 'change-shipment-status.blocked.NOT_FULLY_ASSIGN',
    ALL_SHIPMENTS_NOT_DELIVERED: 'change-shipment-status.blocked.ALL_SHIPMENTS_NOT_DELIVERED',
};

const positiveStatusSet = new Set<ApiTransportOrderStatusT>([TransportOrderStatusEnum.done]);

const negativeStatusSet = new Set<ApiTransportOrderStatusT>([
    TransportOrderStatusEnum.declined,
    TransportOrderStatusEnum.canceled,
]);

const StatusFooterContent: React.FC<PropsT> = React.memo((props) => {
    const { descriptor, transportOrderDetails, transportOrderId, onReAssignment } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { availableSubjectStatuses, fetchRequest, updateRequest } = useSelector(
        selectStateMachineItemState<ApiTransportOrderStatusT>(descriptor),
    );

    const options: Array<DropdownControlOptionT | null | undefined> = [
        ...(availableSubjectStatuses?.description
            ? [
                  {
                      label: (
                          <ChangeBlockedStatusControlOptionLabel
                              reason={t(blockedI18nMap[availableSubjectStatuses.description])}
                          />
                      ),
                      isDisabled: true,
                      onSelect: () => {
                          // nothing
                      },
                  },
              ]
            : []),
        ...(availableSubjectStatuses?.backward || []).map((status) => {
            return {
                label: (
                    <ChangeBackwardStatusControlOptionLabel
                        isPositiveIconStatus={positiveStatusSet.has(status)}
                        isNegativeIconStatus={negativeStatusSet.has(status)}
                        statusNode={<TransportOrderStatus status={status} />}
                    />
                ),
                onSelect: () => {
                    if (!descriptor) {
                        return;
                    }

                    dispatch(
                        updateEntityStatus({
                            update: {
                                entityType: 'TRANSPORTATION_ORDER',
                                entityId: transportOrderId || '-',
                                status,
                            },
                            descriptor,
                        }),
                    );
                },
            };
        }),
        ...(availableSubjectStatuses?.forward || []).map((status) => {
            return {
                label: (
                    <ChangeForwardStatusControlOptionLabel
                        isPositiveIconStatus={positiveStatusSet.has(status)}
                        isNegativeIconStatus={negativeStatusSet.has(status)}
                        statusNode={<TransportOrderStatus status={status} />}
                    />
                ),
                onSelect: () => {
                    if (!descriptor) {
                        return;
                    }

                    dispatch(
                        updateEntityStatus({
                            update: {
                                entityType: 'TRANSPORTATION_ORDER',
                                entityId: transportOrderId || '-',
                                status,
                            },
                            descriptor,
                        }),
                    );
                },
            };
        }),
    ];

    const optionsWithSeparators = addSeparatorAfterEachOption(options);

    const isLoading = fetchRequest?.loading || updateRequest?.loading;

    let assignmentActionNode = null;

    const isNeedAssignment = availableSubjectStatuses?.current === TransportOrderStatusEnum.driverUnassigned;
    const isAllowReassign = availableSubjectStatuses?.current === TransportOrderStatusEnum.assigned;
    if (!isNeedAssignment && isAllowReassign) {
        assignmentActionNode = (
            <Button
                className={cx('button')}
                theme={ButtonThemeEnum.transparent}
                onClick={onReAssignment}
                isLoading={isLoading}
            >
                {t('common:driver-assignment.actions.unassign', {
                    postProcess: 'interval',
                    count: transportOrderDetails?.drivers?.length || 0,
                })}
            </Button>
        );
    }

    return (
        <div className={cx('actions')}>
            <div className={cx('action', 'action--assignment')}>{assignmentActionNode}</div>
            <div className={cx('action', 'action--spacer')} />
            {!!optionsWithSeparators.length && (
                <div className={cx('action', 'action--status-dropdown')}>
                    <DropdownControl
                        options={optionsWithSeparators}
                        overlayPosition={DropdownOverlayPositionEnum.topRight}
                        renderTrigger={(isActive, onClick) => (
                            <TransparentTrigger
                                label={t('common:transport-order-details.change-status')}
                                onClick={onClick}
                                isPressed={isActive}
                                isDisabled={isLoading}
                                className={cx('trigger')}
                                rightIcon={
                                    isLoading ? (
                                        <ControlLoaderIcon
                                            fillColor={StyleGuideColorsEnum.brandAccent}
                                            size={DEFAULT_ICON_SIZE}
                                        />
                                    ) : (
                                        <ArrowsIcon />
                                    )
                                }
                                reflectionTheme={ReflectionThemeEnum.light}
                            />
                        )}
                    />
                </div>
            )}
        </div>
    );
});

export default StatusFooterContent;
