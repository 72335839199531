import classNames from 'classnames/bind';
import styles from './MapPin.scss';
import * as React from 'react';
import { CSSProperties } from 'react';
import cs from 'classnames';

const cx = classNames.bind(styles);

export enum MapPinThemeEnum {
    charcoal = 'charcoal',
    gray = 'gray',
    light = 'light',
    brandDark = 'brand-dark',
}

export type MapPointPropsT = {
    lat?: number | undefined;
    lng?: number | undefined;
    className?: string;
    hasPaddings?: boolean;
    withoutArrow?: boolean;
    hasBorder?: boolean;
    theme?: MapPinThemeEnum;
    style?: CSSProperties;
    children?: React.ReactNode;
};

const MapPin: React.FC<MapPointPropsT> = React.memo((props) => {
    const { hasPaddings, withoutArrow, hasBorder, theme, className, style, children } = props;

    return (
        <div
            className={cs(
                cx('map-tooltip', {
                    'map-tooltip--hasPaddings': hasPaddings,
                    'map-tooltip--hasArrow': !withoutArrow,
                    'map-tooltip--hasBorder': hasBorder,
                    [`map-tooltip--theme-${theme}`]: !!theme,
                }),
                className,
            )}
            style={style}
        >
            {children}
        </div>
    );
});

export default MapPin;
