import React, { useMemo } from 'react';
import classNames from 'classnames/bind';

import styles from './SpotAssetsAssignmentForm.scss';
import { Trans, useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import AssetOptionLabel from './AssetOptionLabel/AssetOptionLabel';
import { useDispatch, useSelector } from 'react-redux';
import SuggestInput from 'design-system/components/dropdowns/SuggestInput/SuggestInput';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import { useFormik } from 'formik';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import {
    selectAssignmentRequestStatus,
    selectTrailerAssignmentInfoById,
    selectTrailerSuggestIds,
    selectTrailerSuggestRequestStatus,
    selectTruckAssignmentInfoById,
    selectTruckSuggestIds,
    selectTruckSuggestRequestStatus,
    selectVehicleLinks,
} from 'common/store/spot-assets-assigment/selectors';
import { CurrencyEnum } from 'common/constants';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import Money from 'common/components/Money/Money';
import { assign, findTrailer, findTruck } from 'common/store/spot-assets-assigment/actions';
import { logWarning } from 'common/utils/logger';
import TrailerIcon, { TrailerIconProps } from 'common/icons/TrailerIcon';
import TruckIcon, { TruckIconProps } from 'common/icons/TruckIcon';

const cx = classNames.bind(styles);

type PropsT = {
    transportOrderId: TransportOrderIdT;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    onCancel: () => void;
};

type AssetOptionT = {
    id: AssetIdT;
    plateNumber: string;
    model?: string;
    ratePerKm?: number;
};

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.truckId]: null,
    [FieldsEnum.trailerId]: null,
};

const SpotAssetsAssignmentForm: React.FC<PropsT> = (props) => {
    const { onCancel, transportOrderId, transportOrderDetails } = props;

    const trailerId = transportOrderDetails?.trailer?.id;
    const truckId = transportOrderDetails?.truck?.id;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const assignmentRequestStatus = useSelector(selectAssignmentRequestStatus);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...INITIAL_VALUES,
            [FieldsEnum.truckId]: truckId || null,
            [FieldsEnum.trailerId]: trailerId || null,
        };

        const errors = validateForm(t, values);

        return [values, errors];
    }, []);

    const formik = useFormik<FormValuesT>({
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const trailerId = values[FieldsEnum.trailerId];
            const truckId = values[FieldsEnum.truckId];
            if (!trailerId || !truckId || !transportOrderId) {
                logWarning('failed to assign assets, empty any parameter');
                return;
            }

            dispatch(assign(transportOrderId, truckId, trailerId));
            formikHelpers.setTouched({});
        },
    });

    const [truckQuery, setTruckQuery] = React.useState<string>('');
    React.useEffect(() => {
        if (!transportOrderId) {
            return;
        }

        dispatch(
            findTruck({
                transportOrderId,
                plateNumber: truckQuery,
            }),
        );
    }, [truckQuery]);

    const links = useSelector(selectVehicleLinks);

    const truckSuggestRequestStatus = useSelector(selectTruckSuggestRequestStatus);
    const truckSuggestIds = useSelector(selectTruckSuggestIds);
    const truckInfoById = useSelector(selectTruckAssignmentInfoById);
    const trucksOptions: AssetOptionT[] = useMemo(() => {
        return truckSuggestIds.map((id) => {
            const info = truckInfoById[id] || null;

            return {
                id,
                plateNumber: info?.plateNumber || '',
                model: info?.model,
                ratePerKm: info?.ratePerKm,
            };
        });
    }, [truckInfoById, truckSuggestIds]);

    const [trailerQuery, setTrailerQuery] = React.useState<string>('');
    React.useEffect(() => {
        if (!transportOrderId) {
            return;
        }

        dispatch(
            findTrailer({
                transportOrderId,
                plateNumber: trailerQuery,
            }),
        );
    }, [trailerQuery]);

    const trailerSuggestRequestStatus = useSelector(selectTrailerSuggestRequestStatus);
    const trailerSuggestIds = useSelector(selectTrailerSuggestIds);
    const trailerInfoById = useSelector(selectTrailerAssignmentInfoById);
    const trailersOptions: AssetOptionT[] = useMemo(() => {
        return trailerSuggestIds.map((id) => {
            const info = trailerInfoById[id] || null;

            return {
                id,
                plateNumber: info?.plateNumber || '',
                model: info?.model,
                ratePerKm: info?.ratePerKm,
            };
        });
    }, [trailerInfoById, trailerSuggestIds]);

    const formatOption = (option: AssetOptionT | null): string => option?.plateNumber || '';
    const getOptionValue = (option: AssetOptionT) => option.id;

    const renderOption = (option: AssetOptionT) => {
        return <AssetOptionLabel plateNumber={option.plateNumber} model={option.model} ratePerKm={option.ratePerKm} />;
    };

    const handleChangeTruck = (_: string, truckId: AssetOptionT['id'] | null) => {
        formik.setFieldValue(FieldsEnum.truckId, truckId);

        const linkedTrailerId = truckId ? links.byTruckId[truckId] : null;
        if (linkedTrailerId) {
            const newTrailerId = truckId ? linkedTrailerId : null;
            formik.setFieldValue(FieldsEnum.trailerId, newTrailerId);
        }
    };

    const handleChangeTrailer = (_: string, trailerId: AssetOptionT['id'] | null) => {
        formik.setFieldValue(FieldsEnum.trailerId, trailerId);

        const linkedTruckId = trailerId ? links.byTrailerId[trailerId] : null;
        if (linkedTruckId) {
            const newTruckId = trailerId ? linkedTruckId : null;
            formik.setFieldValue(FieldsEnum.truckId, newTruckId);
        }
    };

    return (
        <div className={cx('wrap')}>
            <form onSubmit={formik.handleSubmit}>
                <div className={cx('head')}>
                    <div className={cx('head__title')}>{t('common:spot-asset-transport-order-assignment.title')}</div>
                    <div className={cx('head__bid')}>
                        <Trans
                            i18nKey="common:spot-asset-transport-order-assignment.bid"
                            components={{
                                value: (
                                    <Money
                                        amount={transportOrderDetails?.carrierCost?.totalCost}
                                        currency={CurrencyEnum.EUR}
                                    />
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className={cx('form')}>
                    <FormikField
                        className={cx('field')}
                        name={FieldsEnum.truckId}
                        error={formik.errors[FieldsEnum.truckId]}
                        meta={formik.getFieldMeta(FieldsEnum.truckId)}
                        label={t('common:spot-asset-transport-order-assignment.fields.truck.label')}
                        setFieldValue={handleChangeTruck}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <SuggestInput<AssetOptionT, AssetOptionT['id']>
                                selectedValue={formik.values[FieldsEnum.truckId]}
                                options={trucksOptions}
                                onSelect={props.onChange}
                                renderOption={renderOption}
                                isLoading={truckSuggestRequestStatus.loading}
                                formatOption={formatOption}
                                getOptionValue={getOptionValue}
                                onChangeQuery={setTruckQuery}
                                renderLeftIcon={(iconMeta) => (
                                    <TruckIcon {...TruckIconProps.getControlProps(iconMeta)} />
                                )}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                placeholder={t('common:spot-asset-transport-order-assignment.fields.truck.placeholder')}
                                hasOptionsSeparator
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field')}
                        name={FieldsEnum.trailerId}
                        error={formik.errors[FieldsEnum.trailerId]}
                        meta={formik.getFieldMeta(FieldsEnum.trailerId)}
                        label={t('common:spot-asset-transport-order-assignment.fields.trailer.label')}
                        setFieldValue={handleChangeTrailer}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <SuggestInput<AssetOptionT, AssetOptionT['id']>
                                selectedValue={formik.values[FieldsEnum.trailerId]}
                                options={trailersOptions}
                                onSelect={props.onChange}
                                renderOption={renderOption}
                                isLoading={trailerSuggestRequestStatus.loading}
                                formatOption={formatOption}
                                getOptionValue={getOptionValue}
                                onChangeQuery={setTrailerQuery}
                                renderLeftIcon={(iconMeta) => (
                                    <TrailerIcon {...TrailerIconProps.getControlProps(iconMeta)} />
                                )}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                placeholder={t(
                                    'common:spot-asset-transport-order-assignment.fields.trailer.placeholder',
                                )}
                                hasOptionsSeparator
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </div>
                <div className={cx('actions')}>
                    <Button
                        theme={ButtonThemeEnum.secondary}
                        className={cx('action', 'action--cancel')}
                        onClick={onCancel}
                    >
                        {t('common:spot-asset-transport-order-assignment.action.cancel')}
                    </Button>
                    <Button
                        theme={ButtonThemeEnum.primary}
                        className={cx('action', 'action--assign')}
                        isLoading={assignmentRequestStatus.loading}
                        type="submit"
                    >
                        {t('common:spot-asset-transport-order-assignment.action.assign')}
                    </Button>
                </div>
                <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            </form>
        </div>
    );
};

export default SpotAssetsAssignmentForm;
